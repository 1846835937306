<template>
 <div class="line">
    <div class="strTable" :class="{ mb60: table === 'main', more: table === 'more' }" v-if="gameCount">
      <div class="tablePC">
        <ul class="w100p tableHead" v-if="table === 'main' || table === 'mobileMain'">
          <li class="w10p">{{ $t("front.stributor.name") }}</li>
          <li class="w7p" v-if="gameCount['minigame'] || gameCount['mini game']">{{ $t("front.stributor.miniRate") }}</li>
          <li class="w5p">{{ $t("front.stributor.on") }}</li>
          <li class="w12p">{{ $t("front.stributor.onNow") }}</li>
          <li class="w12p">{{ $t("front.stributor.dnws") }}</li>
          <li class="w12p">{{ $t("front.stributor.giveMoney") }}</li>
          <li class="w12p" v-if="gameCount['casino']">{{ $t("front.gnb.casino") }}</li>
          <li class="w12p" v-if="gameCount['slot']">{{ $t("front.gnb.slot") }}</li>
          <li class="w12p" v-if="gameCount['minigame'] || gameCount['mini game']">{{ $t("front.gnb.minigame") }}</li>
          <li class="w12p">{{ $t("front.stributor.allBet") }}</li>
        </ul>
      </div>
      <retail-main-tr
         v-for="item in list"
         :partnerObj="partnerObj"
         :data="item"
         :date="date"
         :key="item"
         @showBet="showBetList"
       />
    </div>
  </div>

  <pagination v-if="mainPageInfo"
    :pageNum="mainPageInfo.page"
    :pageSize="mainPageInfo.count_per_list"
    :totalCount="mainPageInfo.tatal_list_count"
    @goToPage="goToMainPage"
  >
  </pagination>

  <div v-if="isOpenBet" class="userBetWrap mybetwrap">
    <h4>소속회원 베팅리스트<a @click="onClose" class="close"></a></h4>
    <div class="listDateWrap">
      <date-filter
        :retail="true"
        @search="loadBetList(1)"
        @update="onChangeDateSubTable"
        :defaultDay="0"
        :startDate="tableDate.startDate"
        :endDate="tableDate.endDate"
        :isOldYn="true"
        :id="'sub'"
        @setOldYn="setBetOldYn"
        :oldYn="reqDataBet.oldYn"
      />
      <a
        ><img @click="aHover = !aHover" src="@/assets/img/icon_qusetion.svg"
      /></a>
      <div v-if="aHover" class="aHovercon">
        "오늘날짜 기준으로 5일 이전데이터를 검색하시려면 이 체크박스에
        체크해주시기 바랍니다."
      </div>
    </div>
    <ul class="tabs">
      <!--        <li class="tab-link">{{$t('front.gameCategory.all')}}</li>-->
      <template v-for="item in gameGroupCodes" v-bind:key="item.groupCode">
        <li
          class="tab-link"
          :class="{ current: type == item.groupCode }"
          @click="type = item.groupCode"
        >
          <span>{{
            $i18n.locale !== "ko"
              ? item[`groupCodeName${$i18n.locale.toUpperCase()}`]
              : item.groupCodeName
          }}</span>
        </li>
      </template>
    </ul>

    <select v-model="vendorKey" class="gameName">
      <template v-for="item in commonCode[type]" v-bind:key="item.code">
        <option :value="item.code">
          {{
            $i18n.locale !== "ko"
              ? item[`codeName${$i18n.locale.toUpperCase()}`]
              : item.codeName
          }}
        </option>
      </template>
    </select>
    <div class="tab-content">
      <div>
        <div class="board">
          <ul class="boardw first">
            <li>{{ $t("front.board.dates") }}</li>
            <li>{{ $t("front.board.company") }}</li>
            <li>{{ $t("front.board.type") }}</li>
            <li>{{ $t("front.board.gameType") }}</li>
            <li>
              {{ $t("front.bettingStatus.PASS") }}/{{
                $t("front.bettingStatus.FAIL")
              }}
            </li>
            <li>{{ $t("front.board.betMoney") }}</li>
            <li>{{ $t("front.board.revenue") }}</li>
            <li>{{ $t("front.give.endMoney") }}</li>
          </ul>
          <template v-if="bottomBetList.length > 0">
            <template v-for="item in bottomBetList" v-bind:key="item.betIdx">
              <ul class="boardw">
                <li class="">{{ dateFormatAll(item.createdAt) }}</li>
                <li class="">
                  {{
                    $i18n.locale !== "ko"
                      ? item[`vendorName${$i18n.locale.toUpperCase()}`]
                      : item.vendorName
                  }}
                </li>
                <li class="">{{ item.gameType }}</li>
                <li class="">{{ item.gameName }}</li>
                <!--li class=""><a class="detailBtn" @click="detailP=!detailP">{{$t('front.stributor.detail')}}</a></li><-- {{item.gameIdx}} -->
                <li :class="{ rdc: item.betCalResults == 'PASS' }">
                  {{ $t("front.bettingStatus." + item.betCalResults) }}
                </li>
                <li class="">{{ thousand(item.cash) }}</li>
                <li class="">{{ thousand(item.resultCash) }}</li>
                <li class="">{{ thousand(item.afterCash) }}</li>
              </ul>
            </template>
          </template>
          <template v-else>
            <ul class="boardw">
              <li class="">{{ $t("front.board.noBet") }}</li>
            </ul>
          </template>
        </div>
      </div>
    </div>
    <pagination
      :pageNum="pageInfo.page"
      :pageSize="pageInfo.count_per_list"
      :totalCount="pageInfo.tatal_list_count"
      @goToPage="loadBetList"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import RetailMainTr from '@/components/member/stributor/RetailTr.vue'
import { getBottomBetList } from '@/api/retail'
import DateFilter from '@/components/ui/DateFilter'
import Pagination from '@/components/ui/Pagination.vue'
import { getDateStr } from '@/libs/utils'
import { addDays } from 'date-fns'

export default {
  name: 'RetailMainTable',
  components: {
    RetailMainTr,
    Pagination,
    DateFilter
  },
  props: ['date', 'list', 'table', 'partnerObj', 'mainPageInfo'],
  data () {
    return {
      aHover: false,
      isOpenBet: false,
      bottomBetList: [],
      vendorKey: '',
      type: '',
      betTargetId: '',
      pageInfo: {
        page: 1,
        count_per_list: 10,
        tatal_list_count: 10
      },
      reqDataBet: {
        startDate: '',
        endDate: '',
        oldYn: 'N'
      },
      tableDate: {
        startDate: getDateStr(addDays(new Date(), 0), 'yyyy-MM-dd 00:00:00'),
        endDate: getDateStr(addDays(new Date(), 0), 'yyyy-MM-dd 23:59:59')
      }
    }
  },
  watch: {
    isOpenBet () {
      if (this.isOpenBet) {
        this.reqDataBet.startDate = getDateStr(
          new Date(this.tableDate.startDate)
        )
        this.reqDataBet.endDate = getDateStr(new Date(this.tableDate.endDate))
        this.type = this.gameGroupCodes[0].groupCode
        this.defaultValueSetting()
        this.loadBetList()
      }
    },
    type (nVal, oVal) {
      console.log(nVal, oVal)
      // this.onBettingList(this.pageInfo.page, this.gameType)
      if (nVal !== oVal) {
        this.defaultValueSetting()
      }
      this.loadBetList()
    },
    vendorKey () {
      this.loadBetList()
    }
  },
  computed: {
    ...mapState(['gameCount', 'commonCode', 'gameGroupCodes'])
  },
  methods: {
    goToMainPage (page) {
      console.log('11111', page)
      this.$emit('goToMainPage', page)
    },
    onReset () {
      this.bottomBetList = []
      this.tableDate = {
        startDate: getDateStr(addDays(new Date(), 0), 'yyyy-MM-dd 00:00:00'),
        endDate: getDateStr(addDays(new Date(), 0), 'yyyy-MM-dd 23:59:59')
      }

      this.reqDataBet = {
        startDate: '',
        endDate: '',
        oldYn: 'N'
      }
    },
    onClose () {
      this.onReset()
      this.isOpenBet = false
    },
    setBetOldYn (data) {
      this.reqDataBet.oldYn = data
    },
    onChangeDateSubTable (value) {
      console.log(value)
      this.reqDataBet.startDate = getDateStr(new Date(value.startDate))
      this.reqDataBet.endDate = getDateStr(new Date(value.endDate))
    },
    showBetList (targetId) {
      this.betTargetId = targetId
      this.isOpenBet = true
      this.onReset()
    },
    async loadBetList (page) {
      this.emitter.emit('Loading', true)
      if (!page) {
        page = 1
      }
      this.bottomBetList = []
      const params = {
        ...this.reqDataBet,
        page: page,
        memId: this.betTargetId,
        vendorCode: this.vendorKey,
        gameCategory: this.type
      }
      console.log('load------------', params)
      await getBottomBetList(params).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          console.log(result)
          const list = result.data.list

          if (list.length > 0) {
            for (let i = 0, iLen = list.length; i < iLen; i++) {
              const item = list[i]
              item.resultCash = Number(item.winCash) - Number(item.cash)
              if (item.type === 'win') {
                item.betCalResults = 'PASS'
              } else if (item.type === 'lose') {
                item.betCalResults = 'FAIL'
              } else if (item.type === 'draw') {
                item.betCalResults = 'TIE'
              } else if (item.type === 'wait') {
                item.betCalResults = 'WAIT'
              } else {
                item.betCalResults = 'CANCEL'
              }
            }
          }

          this.bottomBetList = list

          if (list.length > 0) {
            this.pageInfo = result.data.pageInfo
          }
          console.log(this.pageInfo)
        }
        this.emitter.emit('Loading', false)
      })
    },
    defaultValueSetting () {
      // this.type = this.gameGroupCodes[0].groupCode
      for (const item in this.commonCode[this.type]) {
        const code = this.commonCode[this.type][item].code
        if (code || code === 0) {
          this.vendorKey = code
          break
        }
      }
    }
  }
}
</script>

<style>
  .w5p {width: 5%;}
  .w7p {width: 9%;}
  .w10p {width: 10%;}
  .w12p {width: 15%;}
  .w100p {width: 100%;}
</style>

<style scoped>
@media screen and (max-width: 800px) {
  .tablePC {display: none;}
  .strTable { width: 100% !important; }

  .mobileTable .tablePC {display: block;}
  .mobileTable .strTable {width: 1320px !important; overflow: inherit !important;}
}
.tableHead {background: #EEF0F5; display: flex;  width: 100%; justify-content: space-between; align-items: center;}
.tableHead li {height: 40px; display: flex; justify-content: center; align-items: center;}
.mybetwrap .tabs {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  margin: 0 25px 28px;
}
.mybetwrap .tabs li {
  text-align: center;
  width: 100%;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  border-radius: 10px;
  border: solid 1px #333;
  background-color: #141414;
  padding: 9px 0;
}
.mybetwrap .tabs li:hover {
  background: #555;
}
.mybetwrap .tabs li.current {
  background: #555;
}

.mybetwrap .board > .boardw.first:first-child {
  background: #555;
  color: #fff;
  border: 0;
  border-radius: 10px 10px 0 0;
}
.mybetwrap .boardw:last-child {
  border-bottom: 1px solid #333;
}
.mybetwrap .boardw {
  width: 100%;
  border-bottom: 1px solid #333;
  background: #222;
  display: flex;
  text-align: center;
  justify-content: space-around;
  align-items: center;
  height: 45px;
  line-height: 1.5em;
  font-size: 14px;
  cursor: pointer;
  color: #fff;
  box-sizing: border-box;
}
.mybetwrap .boardw li {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.2em;
  overflow: hidden;
}
.mybetwrap .boardw li a {
  color: #fff;
}
.listDateWrap {
  margin: 0 25px;
  display: flex;
  align-items: center;
  gap: 20px;
}
.listDateWrap > a {
  margin-bottom: 18px;
}
.aHovercon {
  border: 1px solid #333;
  border-radius: 12px;
  padding: 10px 15px;
  color: #eee;
  background: #141414;
  position: absolute;
  right: 10px;
  top: 140px;
  z-index: 10;
  width: 100%;
  max-width: 280px;
  word-break: break-all;
  white-space: break-spaces;
  line-height: 1.5em;
  font-size: 12px;
}
@media screen and (min-width: 800px) {
  .aHovercon {
    left: 750px;
    top: 85px;
  }
}
</style>
